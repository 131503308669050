import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AthleteUserEntriesIndividualOverrideValidation';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/omeMeetOrgUnitAthleteEntryIndividualRosterEntry/UseOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData';
import useOrgUnitAthleteEntryIndividualRosterEntryData from '../../../../state/orgUnitAthleteEntry/UseOrgUnitAthleteEntryIndividualRosterEntryData';
import useEventMeetEventData from '../../../../state/eventMeetEvent/UseEventMeetEventData';

import Constants from '../../../../../common/utils/Constants';
import UseForm from '../../../../../common/utils/UseForm';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { convertTimeToMilliseconds, formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';

const INITIAL_FORM_STATE = {
  isEdit: false,
  timeCuts: [],
  swimTime: '',
  swimTimeValue: '',
  swimDate: Constants.BLANK_DATE_STRING,
  eventId: Constants.DEFAULT_ID,
  eventName: '',
  courseId: Constants.DEFAULT_ID,
  courseName: '',
  meetName: '',
  hasTimeStandards: false,
  qualifyingStartDate: Constants.BLANK_DATE_STRING,
  qualifyingEndDate: Constants.BLANK_DATE_STRING
};

const useOrgUnitAdminRosterEntriesIndividualOverride = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventMeetEventState } = useEventMeetEventData()
  const { omeMeetState } = useOmeMeetData()
  const { omeMeetOrgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId } = useOmeMeetOrgUnitAthleteEntryIndividualRosterEntryData();
  const { orgUnitAthleteEntryIndividualRosterEntryState, getOrgUnitAthleteEntryIndividualRosterEntry,
    putOrgUnitAthleteEntryAthleteEntryIndividualRosterEntry } = useOrgUnitAthleteEntryIndividualRosterEntryData();
  const [state, setState] = useState({ event: {}, tryGet: false, tryGetLocationState: false, tryRedirect: false, courseOptions: [] });
  const { handleSubmit, formState, errorState, setErrors, setIsDirty, setFormData, onFormValueChanged, onValueTextPairChanged }
    = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    handleSubmit();
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.ATHLETE_ENTRIES_INDIVIDUAL_SELECTION, { state: { tryGet: false } });
    }
    else {
      //Meet Route
      navigate(NavLinks.ATHLETE_ENTRIES_INDIVIDUAL_SELECTION, { state: { tryGet: false } });
    }
  }

  function submitFormCallback(formState) {
    //validate that swim time meets any time cuts
    let errors = {};
    const timeCut = eventMeetEventState.arrayData?.find(x => x.eventId === formState.eventId);
    if (timeCut) {
      const swimTimeTimeFormatted = formatTimeForFilterObject(formState.swimTime);
      const swimTimeTimeInMilliseconds = convertTimeToMilliseconds(swimTimeTimeFormatted);
      const noFasterThanTimeCutFormatted = timeCut?.noFasterThanTimeCut ? formatTimeForFilterObject(formatTimeForDisplay(timeCut?.noFasterThanTimeCut)) : null;
      const noFasterThanTimeCutInMilliseconds = noFasterThanTimeCutFormatted ? convertTimeToMilliseconds(noFasterThanTimeCutFormatted) : null;
      if (noFasterThanTimeCutInMilliseconds !== null && noFasterThanTimeCutInMilliseconds >= 0) {
        if (swimTimeTimeInMilliseconds < noFasterThanTimeCutInMilliseconds) {
          errors.swimTime = `Swim Time cannot be faster than the ${timeCut.eventName} No Faster Than Time Standard Cut (${formatTimeForDisplay(timeCut?.noFasterThanTimeCut)})`;
        }
      }
      const noSlowerThanTimeCutFormatted = timeCut?.noSlowerThanTimeCut ? formatTimeForFilterObject(formatTimeForDisplay(timeCut?.noSlowerThanTimeCut)) : null;
      const noSlowerThanTimeCutInMilliseconds = noSlowerThanTimeCutFormatted ? convertTimeToMilliseconds(noSlowerThanTimeCutFormatted) : null;
      if (noSlowerThanTimeCutInMilliseconds !== null && noSlowerThanTimeCutInMilliseconds >= 0) {
        if (swimTimeTimeInMilliseconds > noSlowerThanTimeCutInMilliseconds) {
          errors.swimTime = `Swim Time cannot be slower than the ${timeCut.eventName} No Slower Than Time Standard Cut (${formatTimeForDisplay(timeCut?.noSlowerThanTimeCut)})`;
        }
      }
    }

    if (Object.keys(errors).length === 0) {
      const qualifyingCourseCode = eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.eventName?.substring(eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.eventName?.length - 3, eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.eventName?.length) !== 'ter' ?
        eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.eventName?.substring(eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.eventName?.length - 3, eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.eventName?.length) : 'OW';

      const index = orgUnitAthleteEntryIndividualRosterEntryState.arrayData?.events?.findIndex(x => x.meetEventId === state.event?.meetEventId);
      orgUnitAthleteEntryIndividualRosterEntryState.arrayData.events[index] = {
        ...state.event,
        entryMeetName: formState.meetName.trim(),
        qualifyingEventId: formState.eventId,
        qualifyingEventName: formState.eventName,
        isAlternateEvent: eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.isAlternateEvent,
        isNonConformingTime: eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.isNonConforming,
        qualifyingCourseId: eventMeetEventState.arrayData.find(x => x.eventId === formState.eventId)?.courseId,
        qualifyingCourseCode: qualifyingCourseCode,
        hasQualifyingTime: false,
        swimDate: formState.swimDate,
        swimTimeEntryOverride: true,
        entryTime: formState.swimTimeValue,
        isSelected: !state.event.individualAthleteMeetEventId ? false : formState.isEdit === true ? true : false
      };

      if (formState.isEdit === true && state.event?.individualAthleteMeetEventId) {
        putOrgUnitAthleteEntryAthleteEntryIndividualRosterEntry(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.orgUnitAthleteEntryId, [orgUnitAthleteEntryIndividualRosterEntryState.arrayData.events[index]]);
        setState({ ...state, tryGet: true, tryGetLocationState: true });
      }
      else {
        setState({ ...state, tryRedirect: true, tryGetLocationState: false });
      }
    }
    else {
      setErrors({ ...errorState, swimTime: errors.swimTime });
    }
  }

  useEffect(() => {
    if (omeMeetState.isObjLoaded === true && location.state.event &&
      eventMeetEventState.isArrayLoading === false && eventMeetEventState.meetEventId === location.state.event?.meetEventId) {
      //Edit
      if (location.state.event?.swimTimeEntryOverride === true) {
        setFormData({
          ...formState,
          isEdit: location.state.event?.entryTime ? true : false,
          timeCuts: location.state.event?.timeCuts || [],
          swimTime: location.state.event?.entryTime ? formatTimeForDisplay(location.state.event?.entryTime) : '',
          swimTimeValue: location.state.event?.entryTime ? location.state.event?.entryTime : '',
          swimDate: location.state.event?.swimDate ? formatDate(location.state.event?.swimDate) : Constants.BLANK_DATE_STRING,
          eventId: location.state.event?.qualifyingEventId ? location.state.event?.qualifyingEventId : Constants.DEFAULT_ID,
          eventName: location.state.event?.qualifyingEventName ? location.state.event?.qualifyingEventName : '',
          courseId: location.state.event?.qualifyingCourseId ? location.state.event?.qualifyingCourseId : Constants.DEFAULT_ID,
          courseName: location.state.event?.qualifyingCourseCode ? location.state.event?.qualifyingCourseCode : '',
          meetName: location.state.event?.entryMeetName ? location.state.event?.entryMeetName : '',
          hasTimeStandards: omeMeetState.objData?.omeMeetQualification[0]?.hasTimeStandards ?? false,
          qualifyingStartDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate ? formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate) : Constants.BLANK_DATE_STRING,
          qualifyingEndDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate ? formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate) : Constants.BLANK_DATE_STRING
        }, true);
      } else {
        //Add
        setFormData({
          ...formState,
          isEdit: location.state.event?.swimTimeEntryOverride ? true : false,
          timeCuts: location.state.event?.timeCuts || [],
          hasTimeStandards: omeMeetState.objData?.omeMeetQualification[0]?.hasTimeStandards ?? false,
          qualifyingStartDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate ? formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingStartDate) : Constants.BLANK_DATE_STRING,
          qualifyingEndDate: omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate ? formatDate(omeMeetState.objData?.omeMeetQualification[0]?.timeStandardsQualifyingEndDate) : Constants.BLANK_DATE_STRING
        }, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetState, eventMeetEventState])

  useEffect(() => {
    if (location.state && location.state.event) {
      const omeMeetCopy = JSON.parse(JSON.stringify(omeMeetState.objData));
      omeMeetCopy.omeMeetEntryCourse.unshift({ courseId: Constants.DEFAULT_ID, course: { courseCode: '--' } });
      const courseOptions = omeMeetCopy.omeMeetEntryCourse.map((o) => { return { id: o.courseId, name: o.course?.courseCode } })
      setState({ ...state, event: location.state.event, courseOptions: courseOptions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.tryGet === true && orgUnitAthleteEntryIndividualRosterEntryState.isSaving === false
      && omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.orgUnitAthleteEntryId) {
      getOrgUnitAthleteEntryIndividualRosterEntry(omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.objData?.orgUnitAthleteEntryId);
      getOrgUnitAthleteEntryIndividualRosterEntryByOrgUnitAthleteEntryId(
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.objData?.orgUnitAthleteEntryId,
        omeMeetOrgUnitAthleteEntryIndividualRosterEntryState?.omeMeetOrgUnitEntryId);
      setState({ ...state, tryGet: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, orgUnitAthleteEntryIndividualRosterEntryState])

  useEffect(() => {
    if (state.tryRedirect === true && orgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false &&
      omeMeetOrgUnitAthleteEntryIndividualRosterEntryState.isArrayLoading === false) {
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.ATHLETE_ENTRIES_INDIVIDUAL_SELECTION, { state: { tryGet: state.tryGetLocationState === true ? true : false } });
      }
      else {
        //Meet Route
        navigate(NavLinks.ATHLETE_ENTRIES_INDIVIDUAL_SELECTION, { state: { tryGet: state.tryGetLocationState === true ? true : false } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, orgUnitAthleteEntryIndividualRosterEntryState, omeMeetOrgUnitAthleteEntryIndividualRosterEntryState])

  return {
    eventMeetEventState,
    state,
    orgUnitAthleteEntryIndividualRosterEntryState,
    omeMeetOrgUnitAthleteEntryIndividualRosterEntryState,
    onSaveClicked,
    onBackClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    formState,
    errorState
  };
}

export default useOrgUnitAdminRosterEntriesIndividualOverride;