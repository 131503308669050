import React, { Fragment } from 'react';

import useMeetUploadErrorTank from './UseMeetUploadErrorTank';

import MeetUploadErrorTankGrid from './MeetUploadErrorTankGrid';
import MeetUploadErrorTankQuickAddGrid from './MeetUploadErrorTankQuickAddGrid';
import MeetTimesPotentialMatchesGrid from './MeetUploadErrorTankPotentialMatchesGrid';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import SearchMemberPopup from '../../../../common/components/searches/searchMember/SearchMemberPopup';
import MeetUploadStatus from '../../../../common/components/infoPanels/meetUploadStatus/MeetUploadStatus';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const ErrorHandle = ({ error, message }) => (
  <Fragment>
    {error && <p className={global.ErrorMessage}>{message}</p>}
  </Fragment>
);

const MeetUploadErrorTank = () => {
  const {
    gridState,
    uiState,
    popupState,
    selectedSwimmerState,
    basicMatchedMemberState,
    meetResultsStatusState,
    handleSubmit,
    onMatchCheckbox,
    submitMatchSelections,
    clearSelections,
    onBackToMatchingButtonClick,
    onQuickAddButtonClick,
    onQuickAddCheckbox,
    onSelectAllButtonClick,
    onUnselectAllButtonClick,
    onDeleteButtonClick,
    onDeleteCheckbox,
    onMatchWithMemberIdButtonClick,
    onCancelMatchWithMemberIdButtonClick,
    onPopupCancel,
    onDeleteSwimmerFromErrorTank,
    onConfirmMatchMemberByIdButtonClick,
    potentialMatchMemberState,
    onRadioChanged,
    onMatchSelectedMember,
    errorMessage,
    formState,
    errorState,
    setFormData,
    onFormValueChanged,
    onSearchForMemberByMemberPopup,
    searchingForMemberByMemberPopUp,
    onMatchSelectedMemberUsingPersonSearch,
    onCancelSearchByMemberIdPopup,
    onRefreshStatus
  } = useMeetUploadErrorTank();

  if (meetResultsStatusState.objData.isProcessing === true) {
    return (
      <MeetUploadStatus statusObject={meetResultsStatusState.objData} onRefreshStatus={onRefreshStatus} />
    )
  }
  else {
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Meet Upload - Times Error Tank</Headings.H3>
          </div>
        </div>
        {uiState.isMatchByIdButtonClicked === true
          ? <form onSubmit={handleSubmit} noValidate>
            <div className="row-usas-extra-top-margin">
              <Headings.H6>Match Times Error Tank Member Using Member ID</Headings.H6>
              <p className={global.HeaderText}><b>Name: </b>{selectedSwimmerState.firstName + ' ' + selectedSwimmerState.lastName}</p>
              <p className={global.HeaderText}><b>Birth Date: </b>{formatDate(selectedSwimmerState.birthDate)}</p>
            </div>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <span className='col-xs-12'>
                  <p className={searchingForMemberByMemberPopUp === false ? global.DisplayComponent : global.HideComponent}>
                    If you do not see a potential match, please click 'Show Member Id Lookup' to search by First Name, Last Name & BirthDate</p>
                  <button className={global.ToggleButton} onClick={onSearchForMemberByMemberPopup}>{searchingForMemberByMemberPopUp === true ? 'Hide Member Id Lookup' : 'Show Member Id Lookup'}</button>
                </span>
                {searchingForMemberByMemberPopUp === true
                  ? <div className='col-xs-12'>
                    <span className='col-xs-12 col-sm-4'>
                      <SearchMemberPopup formState={formState} errorState={errorState} setFormData={setFormData} onFormValueChanged={onFormValueChanged} />
                    </span>
                    {formState?.memberId !== '' && Object?.keys(formState.person)?.length === 0
                      ? <div className='col-xs-12'>
                        <PrimaryButton type="button" onClick={onMatchSelectedMemberUsingPersonSearch}>Match Selected Member</PrimaryButton>&nbsp;
                        <SecondaryButton type="button" onClick={onCancelSearchByMemberIdPopup}>Back</SecondaryButton>
                      </div>
                      : <Fragment />
                    }
                  </div>
                  : <span className={uiState.isUsingPersonSearchToIdentifyMember === false ? global.DisplayComponent : global.HideComponent}>
                    <MeetTimesPotentialMatchesGrid gridData={potentialMatchMemberState} onRadioChanged={onRadioChanged} />
                  </span>
                }
              </div>
            </div>
            <ErrorHandle error={errorMessage} message={errorMessage} />
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin ">
                <span className={(uiState.isUsingPersonSearchToIdentifyMember === false && searchingForMemberByMemberPopUp === false) ? global.DisplayComponent : global.HideComponent}>
                  <PrimaryButton type="button" onClick={onMatchSelectedMember}>Match Selected Member</PrimaryButton>&nbsp;
                  <SecondaryButton type="button" onClick={onCancelMatchWithMemberIdButtonClick}>Back</SecondaryButton>
                </span>
              </div>
            </div>
            {basicMatchedMemberState.isObjLoaded === true
              ? <>
                <div className="row usas-extra-top-margin">
                  <div className="col-xs-12">
                    <p className={global.HeaderText}><b>Name:</b> {basicMatchedMemberState.objData.firstName + ' ' + basicMatchedMemberState.objData.middleName + ' ' + basicMatchedMemberState.objData.lastName}</p>
                    <p className={global.HeaderText}><b>Birth Date:</b> {formatDate(basicMatchedMemberState.objData.birthDate)}</p>
                    <p className={global.HeaderText}><b>Email:</b> {basicMatchedMemberState.objData.email}</p>
                    <p className={global.HeaderText}><b>Member Id:</b> {basicMatchedMemberState.objData.memberId}</p>
                    <p className={global.HeaderText}><b>Competition Category</b> {basicMatchedMemberState.objData.competitionGenderTypeName}</p>
                  </div>
                </div>
                <div className="row usas-extra-top-margin">
                  <div className="col-xs-12">
                    <PrimaryButton type="button" onClick={onConfirmMatchMemberByIdButtonClick}>Confirm Match</PrimaryButton>
                  </div>
                </div>
              </>
              : <Fragment />
            }
          </form>
          : <Fragment />
        }

        {uiState.isMatchByIdButtonClicked === true
          ? <></>
          : <>
            <div className="row">
              <div className='col-xs-12'>
                {uiState.isMatchedButtonClicked === false
                  ? <Headings.H6>
                    <div style={{ marginLeft: 40 }}>
                      <ul>
                        <li>Select any potential matches, then click 'Match Selected Swimmers'</li>
                        <li>If no potential matches exist, click 'Retrieve Unmatchable Swimmers' to continue to the Unmatched Swimmers list</li>
                      </ul>
                    </div>
                  </Headings.H6>
                  : <Fragment />
                }
              </div>
            </div>
            <div className="row usas-extra-top-margin">
              <div className='col-xs-12'>
                {uiState.isMatchedButtonClicked === false
                  ? <MeetUploadErrorTankGrid
                    gridData={gridState.arrayData}
                    isLoading={gridState.isArrayLoading}
                    onMatchCheckbox={onMatchCheckbox} />
                  : <MeetUploadErrorTankQuickAddGrid
                    gridData={gridState.arrayData}
                    isLoading={gridState.isSaving}
                    onQuickAddCheckbox={onQuickAddCheckbox}
                    onDeleteCheckbox={onDeleteCheckbox}
                    onMatchWithMemberIdButtonClick={onMatchWithMemberIdButtonClick} />
                }
              </div>
            </div>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                {uiState.isMatchedButtonClicked === false
                  ? <>
                    <PrimaryButton type="button" onClick={submitMatchSelections}>
                      {gridState.arrayData.length > 0 ? 'Match Selected Swimmers' : 'Retrieve Unmatchable Swimmers'}
                    </PrimaryButton>&nbsp;
                    <SecondaryButton type="button" onClick={clearSelections}>Clear Selections</SecondaryButton>
                  </>
                  : searchingForMemberByMemberPopUp === true
                    ? <Fragment />
                    : <>
                      {gridState.arrayData.find(({ isFina }) => isFina === true) !== undefined ?
                        gridState.arrayData.find(({ isFina }) => isFina === true).isFina === true ?
                          <> <PrimaryButton type="button" onClick={onQuickAddButtonClick}>Quick Add Selected Swimmers</PrimaryButton>&nbsp; </>
                          : <Fragment />
                        : <Fragment />}
                      <PrimaryButton type="button" onClick={onDeleteButtonClick}>Delete Selected Swimmers</PrimaryButton>&nbsp;
                      <SecondaryButton type="button" onClick={onBackToMatchingButtonClick}>Back to Matching</SecondaryButton>&nbsp;
                      <SecondaryButton type="button" onClick={onUnselectAllButtonClick}>Unselect All</SecondaryButton>&nbsp;
                      <SecondaryButton type="button" onClick={onSelectAllButtonClick}>Select All</SecondaryButton>
                    </>
                }
              </div>
            </div>
          </>
        }
        <PopUpModal
          widthPct={30}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={
            gridState.isSaving ||
            gridState.isArrayLoading ||
            basicMatchedMemberState.isObjLoading ||
            meetResultsStatusState.isObjLoading
          } />
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title="Times Delete Warning"
          displayPopUp={popupState.displayModal}
          onModalCanceled={onPopupCancel}>
          <div className="row">
            <div className="col-xs-12">
              <p>{popupState.displayMessage}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onDeleteSwimmerFromErrorTank}>Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onPopupCancel}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      </Fragment >
    );
  }
}

export default MeetUploadErrorTank;