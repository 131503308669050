import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../NavLinks';

import validate from './MeetAdminStaffDetailValidation'

import useMeetAdminStaffData from '../../../state/meetAdminStaff/UseMeetAdminStaffData';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import UseForm from "../../../../common/utils/UseForm";

const useMeetAdminStaffDetail = () => {
  const navigate = useNavigate();
  const { meetState, getMeet } = useMeetData();
  const { meetAdminStaffState, postMeetAdminStaff, confirmSaveMeetAdminStaff } = useMeetAdminStaffData();
  const { formState, errorState, setFormData, handleSubmit, onFormValueChanged, setIsDirty
  } = UseForm(getInitialFormState(), submitFormCallback, validate);
  const { environmentVariableState, MEET_ADMINISTRATOR_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const [state, setState] = useState({ tryGetMeet: false, tryRedirect: false });

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    handleSubmit();
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_ADMIN_STAFF);
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      setFormData({
        ...formState,
        MEET_ADMINISTRATOR_ROLE_GROUP_ID: MEET_ADMINISTRATOR_ROLE_GROUP_ID || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState])

  useEffect(() => {
    if (state.tryGetMeet === true && meetAdminStaffState.isSaving === false &&
      meetAdminStaffState.isSaved === true) {
      confirmSaveMeetAdminStaff();
      getMeet(meetState.objData?.meetId);
      setState({ ...state, tryGetMeet: false, tryRedirect: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGetMeet, meetAdminStaffState])

  useEffect(() => {
    if (state.tryRedirect === true && meetState.isObjLoading === false &&
      meetState.isObjLoaded === true) {
      navigate(NavLinks.MEET_ADMIN_STAFF, { state: { tryGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, meetState])

  function submitFormCallback(formState) {
    const adminStaffObj = {
      personId: formState.personId
    };
    postMeetAdminStaff(meetState.objData?.meetId, adminStaffObj);
    setState({ ...state, tryGetMeet: true });
  }

  function getInitialFormState() {
    return ({
      meetAdminStaffTypeId: '',
      meetAdminStaffTypeName: 'Meet Administrator',
      personId: '',
      memberId: '',
      MEET_ADMINISTRATOR_ROLE_GROUP_ID: ''
    })
  }

  return {
    meetAdminStaffState,
    meetState,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onSaveButtonClicked,
    onBackButtonClicked
  };
};

export default useMeetAdminStaffDetail;