import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../NavLinks';

import useMeetAdminStaffData from '../../../state/meetAdminStaff/UseMeetAdminStaffData';

import useMeetData from '../../../../common/state/meet/UseMeetData';

import Constants from '../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Delete Meet Administrator?',
  staff: {}
};

const useMeetAdminStaff = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetState } = useMeetData();
  const { meetAdminStaffState, getMeetAdminStaffByMeetId, deleteAdminStaffByPersonMeetStaffTypeId,
    confirmSaveMeetAdminStaff } = useMeetAdminStaffData();
  const [state, setState] = useState({ tryGet: false });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onAddAdminStaff = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_ADMIN_STAFF_DETAIL);
  }

  const onDeleteAdminStaffClicked = (e, staff) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setModalState({ ...modalState, displayPopUp: true, staff: staff })
  }

  const onDeleteAdminStaff = (e, staff) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (staff.personMeetStaffTypeId) {
      deleteAdminStaffByPersonMeetStaffTypeId(staff.personMeetStaffTypeId);
      setState({ ...state, tryGet: true });
      onModalCanceled();
    }
  }

  const onModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (meetState.isObjLoaded === true && meetAdminStaffState.isArrayLoading !== true && meetAdminStaffState.isSaving !== true
      && (meetAdminStaffState.isArrayLoaded !== true || state.tryGet === true)) {
      getMeetAdminStaffByMeetId(meetState.objData?.meetId);
      if (state.tryGet === true) {
        setState({ ...state, tryGet: false });
      }
      if (meetAdminStaffState.isSaved === true) {
        confirmSaveMeetAdminStaff();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isObjLoaded, meetAdminStaffState, state.tryGet])

  return {
    meetAdminStaffState,
    isLoading: meetAdminStaffState.isArrayLoading,
    isSaving: meetAdminStaffState.isSaving,
    Constants,
    onAddAdminStaff,
    onDeleteAdminStaff,
    modalState,
    onDeleteAdminStaffClicked,
    onModalCanceled
  };
}

export default useMeetAdminStaff;