import { Fragment } from 'react';

import MeetAdminStaffLargeGrid from './MeetAdminStaffLargeGrid';
import MeetAdminStaffSmallGrid from './MeetAdminStaffSmallGrid';

const MeetAdminStaffGrid = ({ gridData, isLoading, onDeleteAdminStaff }) => {
  return (
    <Fragment>
      <MeetAdminStaffLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onDeleteAdminStaff={onDeleteAdminStaff}
      />
      <MeetAdminStaffSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onDeleteAdminStaff={onDeleteAdminStaff}
      />
    </Fragment>
  )
};

export default MeetAdminStaffGrid;