import { useContext } from 'react';

import { MeetAdminStaffStateContext } from './MeetAdminStaffContextProvider';

import MeetAdminStaffData from './MeetAdminStaffData';

const useMeetAdminStaffData = () => {
  const [meetAdminStaffState, setMeetAdminStaffState] = useContext(MeetAdminStaffStateContext);

  const getMeetAdminStaffByMeetId = (meetId) => {
    MeetAdminStaffData.getMeetAdminStaffByMeetIdData(meetId, meetAdminStaffState, setMeetAdminStaffState);
  };

  const postMeetAdminStaff = (meetId, adminStaffObj) => {
    MeetAdminStaffData.postMeetAdminStaffData(meetId, adminStaffObj, meetAdminStaffState, setMeetAdminStaffState);
  };

  const deleteAdminStaffByPersonMeetStaffTypeId = (personMeetStaffTypeId) => {
    MeetAdminStaffData.deleteAdminStaffByPersonMeetStaffTypeId(personMeetStaffTypeId, meetAdminStaffState, setMeetAdminStaffState);
  };

  const confirmSaveMeetAdminStaff = () => {
    setMeetAdminStaffState({
      ...meetAdminStaffState,
      isSaved: false
    });
  };

  return {
    meetAdminStaffState,
    setMeetAdminStaffState,
    getMeetAdminStaffByMeetId,
    postMeetAdminStaff,
    deleteAdminStaffByPersonMeetStaffTypeId,
    confirmSaveMeetAdminStaff
  };
};

export default useMeetAdminStaffData;