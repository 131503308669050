import { createContext, useState } from 'react';

import MeetAdminStaffData from './MeetAdminStaffData';

export const MeetAdminStaffStateContext = createContext();

const MeetAdminStaffContextProvider = ({ children }) => {
  const stateHook = useState(MeetAdminStaffData.INITIAL_STATE);

  return (
    <MeetAdminStaffStateContext.Provider value={stateHook}>
      {children}
    </MeetAdminStaffStateContext.Provider>
  );
};

export default MeetAdminStaffContextProvider;