import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetAdminStaffSmallGrid = ({ gridData, isLoading, onDeleteAdminStaff }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ?
        gridData.map((staff) =>
        (<div className={global.SmallTableRow} key={staff.personMeetStaffTypeId}>
          <div className={global.SmallTableRowHead}><span className='hidden-xs'>{staff.firstName || <span>&nbsp;</span>} {staff.lastName || <span>&nbsp;</span>}</span>&nbsp;
            {onDeleteAdminStaff &&
              <button className={global.IconButton}
                type="button"
                onClick={(e) => onDeleteAdminStaff(e, staff)}>
                <DeleteIcon />
              </button>}
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Meet Administrator Name</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{staff.firstName || <span>&nbsp;</span>} {staff.lastName || <span>&nbsp;</span>}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-2'].join(' ')}>Email</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-4'].join(' ')}>{staff.email || <span>&nbsp;</span>}</div>
            </div>
          </div>
        </div>)
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meet Administrators</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetAdminStaffSmallGrid;