import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetAdminStaffByMeetIdData from './GetMeetAdminStaffByMeetIdData';
import postMeetAdminStaffData from './PostMeetAdminStaffData';
import deleteAdminStaffByPersonMeetStaffTypeId from './DeleteMeetAdminStaffByPersonMeetStaffTypeIdData';

export const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetAdminStaffData = {
  getMeetAdminStaffByMeetIdData,
  postMeetAdminStaffData,
  deleteAdminStaffByPersonMeetStaffTypeId,
  INITIAL_STATE
};

export default MeetAdminStaffData;