import React, { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ matchItem, onMatchCheckbox }) => {
  return (
    <Fragment>
      <tr>
        <td style={(matchItem.isRepeat === true) ? { backgroundColor: 'blue' } : {}}></td>
        <td>
          {matchItem.errLscCode + '/' + matchItem.errClubCode}
        </td>
        <td style={(matchItem.firstName !== matchItem.errFirstName) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}}>
          {matchItem.errFirstName || ''}
        </td>
        <td style={(matchItem.lastName !== matchItem.errLastName) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}}>
          {matchItem.errLastName || ''}
        </td>
        <td style={(matchItem.birthDate !== matchItem.errBirthDate) ? { backgroundColor: 'lightblue', borderColor: 'darkblue', border: 'solid' } : {}}>
          {formatDate(matchItem.errBirthDate) || ''}
        </td>
        <td style={{ backgroundColor: '#e02a4f', borderTopColor: '#e02a4f' }}></td>
        <td>
          {matchItem.lscCode + '/' + matchItem.clubCode}
        </td>
        <td>
          {matchItem.firstName || ''}&nbsp;{matchItem.middleName || ''}
        </td>
        <td>
          {matchItem.lastName || ''}
        </td>
        <td>
          {formatDate(matchItem.birthDate) || ''}
        </td>
        <td>
          <Checkbox
            label=""
            name="matchedPerson"
            onChange={(e) => { onMatchCheckbox(e, matchItem.personId) }}
            checked={matchItem.matchedPerson} />
        </td>
      </tr>
    </Fragment>
  );
};

const MeetUploadErrorTankLargeGrid = ({ gridData, isLoading, onMatchCheckbox }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th colSpan="6">Athletes From File</th>
          <th colSpan="5">Potential Matches Found</th>
        </tr>
        <tr>
          <th></th>
          <th>LSC / Club Code</th>
          <th>First Name (File)</th>
          <th>Last Name (File)</th>
          <th>Birth Date (File)</th>
          <th></th>
          <th>LSC / Club Code</th>
          <th>First Name - Middle Name (Match)</th>
          <th>Last Name (Match)</th>
          <th>Birth Date (Match)</th>
          <th>Match</th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="11">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((match, i) => <GridRow key={i} matchItem={match} onMatchCheckbox={onMatchCheckbox} />)
            :
            <tr><td colSpan="11">No Results</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetUploadErrorTankLargeGrid;