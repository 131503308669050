import { useEffect, useState } from 'react';

import useLaneSheetData from '../../../state/laneSheet/UseLaneSheetData';
import useLaneSheetHeatData from '../../../state/laneSheetHeat/UseLaneSheetHeatData';

import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';

import ToIntIfInt from '../../../../common/utils/ToIntIfInt';
import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_POST_HEAT_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_DELETE_HEAT_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_DELETE_MODAL_STATE = {
  displayPopUp: false,
  laneSheetHeat: {}
};

const INITIAL_HEAT_POPUP_STATE = {
  displayPopUp: false,
  isRelay: false,
  laneSheetHeat: {}
};

const INITIAL_REPORTS_STATE = {
  reportParameters: { meetId: '' },
  contextId: '',
  routeName: ''
};

const useLaneSheetHeats = () => {
  const { laneSheetState } = useLaneSheetData();
  const { laneSheetHeatState, postBulkLaneSheetHeatOme, postPopulateLaneSheetHeatFinals, postLaneSheetHeat,
    getLaneSheetHeats, putLaneSheetHeatDisplayOrder, deleteLaneSheetHeat
  } = useLaneSheetHeatData();
  const { getEmbeddedReportListForContext } = useEmbeddedReportData();
  const [postHeatState, setPostHeatState] = useState(INITIAL_POST_HEAT_STATE);
  const [deleteHeatState, setDeleteHeatState] = useState(INITIAL_DELETE_HEAT_STATE);
  const [deleteModalState, setDeleteModalState] = useState(INITIAL_DELETE_MODAL_STATE);
  const [heatPopupState, setHeatPopupState] = useState(INITIAL_HEAT_POPUP_STATE);
  const [gridState, setGridState] = useState([]);
  const [state, setState] = useState({ showArchived: false });
  const [reportsState, setReportsState] = useState(INITIAL_REPORTS_STATE);

  const onBulkAddFromOmeClicked = () => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const postBulkLaneSheetHeatOmePromise = postBulkLaneSheetHeatOme(laneSheetMeetId);

      if (postBulkLaneSheetHeatOmePromise !== null) {
        postBulkLaneSheetHeatOmePromise.then((newState) => {
          if (newState !== null) {
            setGridState(createLaneSheetHeatGridData(newState.arrayData));
          }
        }).catch((e) => {
          //context error
        });
      }
    }
  };

  const onPrePopulateForFinalsClicked = () => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const postPopulateLaneSheetHeatFinalsPromise = postPopulateLaneSheetHeatFinals(laneSheetMeetId);

      if (postPopulateLaneSheetHeatFinalsPromise !== null) {
        postPopulateLaneSheetHeatFinalsPromise.then((newState) => {
          if (newState !== null) {
            setGridState(createLaneSheetHeatGridData(newState.arrayData));
          }
        }).catch((e) => {
          //context error
        });
      }
    }
  };

  const onSubmitFormCallback = (formState) => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const postLaneSheetHeatPromise = postLaneSheetHeat(laneSheetMeetId, createLaneSheetHeatObj(formState), postHeatState, setPostHeatState);

      if (postLaneSheetHeatPromise !== null) {
        postLaneSheetHeatPromise.then((newState) => {
          if (newState !== null) {
            const getLaneSheetHeatsPromise = getLaneSheetHeats(laneSheetMeetId);

            if (getLaneSheetHeatsPromise !== null) {
              getLaneSheetHeatsPromise.then((newState) => {
                if (newState !== null) {
                  setGridState(createLaneSheetHeatGridData(newState.arrayData));
                }
              }).catch((e) => {
                //context error
              })
            }
          }
        }).catch((e) => {
          //local error
        });
      }
    }
  };

  const onDeleteLaneSheetHeatClicked = (laneSheetHeat) => {
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      laneSheetHeat
    });
  };

  const onDeleteModalCancelClicked = () => {
    setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });
  };

  const onDeleteModalDeleteClicked = () => {
    const laneSheetHeatId = deleteModalState.laneSheetHeat?.laneSheetHeatId;

    if (laneSheetHeatId > 0) {
      const deleteLaneSheetHeatPromise = deleteLaneSheetHeat(laneSheetHeatId, deleteHeatState, setDeleteHeatState);

      setDeleteModalState({ ...INITIAL_DELETE_MODAL_STATE });

      if (deleteLaneSheetHeatPromise !== null) {
        deleteLaneSheetHeatPromise.then((newState) => {
          if (newState !== null) {
            const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

            if (laneSheetMeetId > 0) {
              const getLaneSheetHeatsPromise = getLaneSheetHeats(laneSheetMeetId);

              if (getLaneSheetHeatsPromise !== null) {
                getLaneSheetHeatsPromise.then((newState) => {
                  if (newState !== null) {
                    setGridState(createLaneSheetHeatGridData(newState.arrayData));
                  }
                }).catch((e) => {
                  //context error
                })
              }
            }
          }
        }).catch((e) => {
          // console.log(e)
        });
      }
    }
  };

  const onDisplayHeatPopupClicked = (laneSheetHeat) => {
    setHeatPopupState({
      ...heatPopupState,
      displayPopUp: true,
      isRelay: laneSheetHeat?.isRelay === true,
      laneSheetHeat
    });
  };

  const onHideHeatPopupClicked = () => {
    setHeatPopupState({ ...INITIAL_HEAT_POPUP_STATE });
  };

  const onUpdateDisplayOrder = (laneSheetHeat, value) => {
    const newGridData = JSON.parse(JSON.stringify(gridState));
    const oldValue = laneSheetHeat.displayOrder;
    const newValue = ToIntIfInt(value);

    for (const heat of newGridData) {
      if (heat.laneSheetHeatId === laneSheetHeat.laneSheetHeatId) {
        heat.displayOrder = newValue;
      } else if (heat.displayOrder === newValue) {
        heat.displayOrder = oldValue;
      }
    }

    setGridState(newGridData);
  };

  const onUpdateIsSelected = (laneSheetHeat) => {
    const newGridData = JSON.parse(JSON.stringify(gridState));
    const newValue = laneSheetHeat.isSelected === true ? false : true;

    for (const heat of newGridData) {
      if (heat.laneSheetHeatId === laneSheetHeat.laneSheetHeatId) {
        heat.isSelected = newValue;
      }
    }

    setGridState(newGridData);
  };

  const onUpdateButtonClicked = () => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;
    if (laneSheetMeetId > 0) {
      const putLaneSheetHeatDisplayOrderPromise = putLaneSheetHeatDisplayOrder(laneSheetMeetId, createLaneSheetHeatsDisplayOrderArray(gridState));

      if (putLaneSheetHeatDisplayOrderPromise !== null) {
        putLaneSheetHeatDisplayOrderPromise.then((newState) => {
          if (newState !== null) {
            setGridState(createLaneSheetHeatGridData(newState.arrayData));
          }
        }).catch((e) => {
          //context error
        });
      }
    }
  };

  const onArchiveSelectedEventsClicked = () => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;
    if (laneSheetMeetId > 0) {

      //Set selected data to isArchived = true
      const gridStateCopy = JSON.parse(JSON.stringify(gridState));
      for (let i = 0; i < gridStateCopy.length; i++) {
        if (gridStateCopy[i].isSelected === true) {
          gridStateCopy[i].isArchived = true;
        }
      }

      const putLaneSheetHeatDisplayOrderPromise = putLaneSheetHeatDisplayOrder(laneSheetMeetId, createLaneSheetHeatsDisplayOrderArray(gridStateCopy));

      if (putLaneSheetHeatDisplayOrderPromise !== null) {
        putLaneSheetHeatDisplayOrderPromise.then((newState) => {
          if (newState !== null) {
            setGridState(createLaneSheetHeatGridData(newState.arrayData));
          }
        }).catch((e) => {
          //context error
        });
      }
    }
  };

  const onUnarchiveSelectedEventsClicked = () => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;
    if (laneSheetMeetId > 0) {

      //Set selected data to isArchived = false
      const gridStateCopy = JSON.parse(JSON.stringify(gridState));
      for (let i = 0; i < gridStateCopy.length; i++) {
        if (gridStateCopy[i].isSelected === true) {
          gridStateCopy[i].isArchived = false;
        }
      }

      const putLaneSheetHeatDisplayOrderPromise = putLaneSheetHeatDisplayOrder(laneSheetMeetId, createLaneSheetHeatsDisplayOrderArray(gridStateCopy));

      if (putLaneSheetHeatDisplayOrderPromise !== null) {
        putLaneSheetHeatDisplayOrderPromise.then((newState) => {
          if (newState !== null) {
            setGridState(createLaneSheetHeatGridData(newState.arrayData));
          }
        }).catch((e) => {
          //context error
        });
      }
    }
  };

  const onGenerateLaneSheetsReport = () => {
    const meetId = laneSheetState.objData?.meetId;
    // const selectedHeats = gridState.filter((x) => x.isSelected === true);

    if (meetId > 0) {
      setReportsState({
        ...reportsState,
        reportParameters: { meetId },
        contextId: meetId,
        routeName: 'LANE_SHEET_LANE_SHEETS',
      });
    }
  };

  const onCloseLaneSheetsReport = () => {
    setReportsState({ ...INITIAL_REPORTS_STATE });
  };

  useEffect(() => {
    const laneSheetMeetId = laneSheetState.objData?.laneSheetMeetId;

    if (laneSheetMeetId > 0) {
      const getLaneSheetHeatsPromise = getLaneSheetHeats(laneSheetMeetId);

      if (getLaneSheetHeatsPromise !== null) {
        getLaneSheetHeatsPromise.then((newState) => {
          if (newState !== null) {
            setGridState(createLaneSheetHeatGridData(newState.arrayData));
          }
        }).catch((e) => {
          //context error
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneSheetState]);

  useEffect(() => {
    if (reportsState.routeName && reportsState.contextId) {
      const getEmbeddedReportListPromise = getEmbeddedReportListForContext(reportsState.routeName, reportsState.contextId);

      if (getEmbeddedReportListPromise ?? false) {
        getEmbeddedReportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const listNames = [];

            stateObj.arrayData.forEach(x => {
              if (listNames.filter(a => a.id === x.listName).length === 0) {
                listNames.push({ id: x.listName, name: x.listDisplayName });
              }
            });

            setReportsState({
              ...reportsState,
              isLoading: false,
              showGrid: false,
              showReport: stateObj.arrayData.length === 1,
              reportInfoId: stateObj.arrayData.length === 1 ? stateObj.arrayData[0].reportInfoId : -1,
              listNames,
              rawReportList: [...stateObj.arrayData],
              reportList: [...stateObj.arrayData]
            });
          } else {
            setReportsState({
              ...reportsState,
              isLoading: false,
              showGrid: false,
              showReport: false,
              reportInfoId: -1,
              listNames: [],
              rawReportList: [],
              reportList: []
            });
          }
        }).catch((e) => {
          setReportsState({
            ...reportsState,
            isLoading: false,
            showGrid: false,
            showReport: false,
            reportInfoId: -1,
            listNames: [],
            rawReportList: [],
            reportList: []
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsState.routeName, reportsState.contextId]);

  function createLaneSheetHeatGridData(laneSheetHeats) {
    const heatsCopy = JSON.parse(JSON.stringify(laneSheetHeats));
    const gridData = [];

    heatsCopy.forEach((heat) => {
      gridData.push({
        ...heat,
        isSelected: false
      });
    });

    return gridData;
  }

  function createLaneSheetHeatsDisplayOrderArray(laneSheetHeats) {
    const laneSheetHeatDisplayOrderArray = [];

    laneSheetHeats.forEach((heat) => {
      laneSheetHeatDisplayOrderArray.push({
        laneSheetHeatId: heat.laneSheetHeatId,
        displayOrder: ToIntIfInt(heat.displayOrder),
        isArchived: heat.isArchived
      });
    });

    return laneSheetHeatDisplayOrderArray;
  }

  function createLaneSheetHeatObj(formState) {
    let laneSheetMeetEventArrayOfIds = []

    for (const meetEvents of formState.eventNameAndDistanceId) {
      if (meetEvents.id > 0) {
        laneSheetMeetEventArrayOfIds.push(meetEvents.id);
      }
    }

    return {
      laneSheetMeetEventIds: laneSheetMeetEventArrayOfIds,
      sessionTypeId: formState.sessionTypeId,
      heatNumber: formState.heatNumber,
      heatDate: formState.heatDate
    };
  }

  return {
    isSaving: postHeatState.isObjLoading || deleteHeatState.isObjLoading,
    isLoading: laneSheetHeatState.isArrayLoading,
    gridData: gridState,
    reportsState,
    laneSheetState,
    deleteModalState,
    heatPopupState,
    state,
    setState,
    onBulkAddFromOmeClicked,
    onPrePopulateForFinalsClicked,
    onSubmitFormCallback,
    onDeleteLaneSheetHeatClicked,
    onDeleteModalCancelClicked,
    onDeleteModalDeleteClicked,
    onDisplayHeatPopupClicked,
    onHideHeatPopupClicked,
    onUpdateDisplayOrder,
    onUpdateButtonClicked,
    onUpdateIsSelected,
    onGenerateLaneSheetsReport,
    onUnarchiveSelectedEventsClicked,
    onArchiveSelectedEventsClicked,
    onCloseLaneSheetsReport
  };
};

export default useLaneSheetHeats;