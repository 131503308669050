import { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ staff, onDeleteAdminStaff }) => {
  return (
    <Fragment>
      <tr>
        <td>{staff.firstName || ''} {staff.lastName || ''}</td>
        <td>{staff.email || ''}</td>
        <td>
          {onDeleteAdminStaff &&
            <button className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onDeleteAdminStaff(e, staff)}>
              <DeleteIcon />
            </button>}
        </td>
      </tr>
    </Fragment>
  );
};

const MeetAdminStaffLargeGrid = ({ gridData, isLoading, onDeleteAdminStaff }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Meet Administrator Name</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="3">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0 ?
            gridData.map((staff) =>
              <GridRow
                key={staff.personMeetStaffTypeId}
                staff={staff}
                onDeleteAdminStaff={onDeleteAdminStaff} />)
            :
            <tr>
              <td colSpan={3}>No Meet Administrators</td>
            </tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default MeetAdminStaffLargeGrid;