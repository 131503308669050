import { isValidDate, isValidBirthDate, isValidSwimTime } from '../../../../../common/utils/validation';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  const errors = {};

  if (formState.hasTimeStandards === true) {
    const qualifyingStartDate = new Date(formState.qualifyingStartDate);
    const qualifyingEndDate = new Date(formState.qualifyingEndDate);
    const swimDate = new Date(formState.swimDate);

    if (swimDate < qualifyingStartDate || swimDate > qualifyingEndDate) {
      errors.swimDate = `Swim Date must be between the Qualifying Start Date (${formState.qualifyingStartDate}) and the Qualifying End Date (${formState.qualifyingEndDate})`
    }
  }

  if (formState.swimDate === Constants.BLANK_DATE_STRING) {
    errors.swimDate = 'Swim Date is required';
  } else if (!isValidDate(formState.swimDate)) {
    errors.swimDate = 'Swim Date must be a valid date'
  } else if (!isValidBirthDate(formState.swimDate)) {
    errors.swimDate = 'Swim Date cannot be in the future'
  }


  if (formState.eventId === Constants.DEFAULT_ID || formState.eventName === '') {
    errors.eventId = 'Event is required';
  }


  if (formState.swimTime === '' || formState.swimTime === 'NT') {
    errors.swimTime = 'Swim Time is required';
  } else if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.swimTimeValue)))) {
    errors.swimTime = 'Swim Time must be a valid time';
  } 
  
  if (formState.swimTimeValue === Constants.MIN_SWIM_TIME_STRING) {
    errors.swimTime = `Swim Time must be a valid time`;
  } 

  if (formState.meetName.trim() === '') {
    errors.meetName = 'Meet Name is required'
  } else if (formState.meetName.trim().length > 200) {
    errors.meetName = 'Meet Name cannot exceed 200 characters'
  }

  return errors;
};

const OrgUnitAdminRosterEntriesBonusIndividualOverrideValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OrgUnitAdminRosterEntriesBonusIndividualOverrideValidation;