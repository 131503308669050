/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../icons/EditIcon';
import PlusIcon from '../../icons/PlusIcon';

import SGColumnHeader from '../../grids/SGColumnHeader';

import useSortableGrid from '../../../utils/UseSortableGrid';
import SGConstants from '../../../utils/SGConstants';

import global from '../../GlobalStyle.module.css';

const LargeGrid = ({ state, onEdit, onCancelClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Classification'} columnField={'meetClassification'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Start Date - End Date'} columnField={'startDate'} 
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Location'} columnField={'meetLocation'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Organization'} columnField={'organization'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          {onCancelClicked ?
            <th></th> : <Fragment />}
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <Fragment>
              {state.isArrayLoading === true
                ? (
                  <tr>
                    {onCancelClicked
                      ? <td colSpan="6">Loading...</td>
                      : <td colSpan="5">Loading...</td>
                    }
                  </tr>
                ) : (
                  <tr>
                    {onCancelClicked
                      ? <td colSpan="6">No Results</td>
                      : <td colSpan="5">No Results</td>
                    }
                  </tr>
                )
              }
            </Fragment>
          ) : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((meet, i) => (
              <tr key={i}>
                {onCancelClicked ?
                  <td>{meet.meetName || ''}</td> :
                  <td><a onClick={(e) => onEdit(e, meet)}>{meet.meetName || ''}</a></td>}
                <td>{meet.meetClassification || ''}</td>
                <td>{meet.dateRange || ''}</td>
                <td>{meet.meetLocation || ''}</td>
                <td>{meet.organization || ''}</td>
                {onCancelClicked
                  ? (
                    <td>
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, meet)}>
                        <PlusIcon />
                      </button>
                    </td>
                  ) : <Fragment />
                }
              </tr>
            )) : (
              <tr>
                {onCancelClicked
                  ? <td colSpan="6">No Results</td>
                  : <td colSpan="5">No Results</td>
                }
              </tr>
            )
        }
      </tbody>
    </table >
  );
};

const SmallGrid = ({ state, onEdit, onCancelClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Results</div>
            }
          </div>
        </div>
      )
      : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((meet, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{meet.meetName || <span>&nbsp;</span>}</span>&nbsp;
              {onCancelClicked ?
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, meet)}>
                  <PlusIcon />
                </button> :
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, meet)}>
                  <EditIcon />
                </button>
              }
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Meet Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{meet.meetName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Classification</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.meetClassification}</div>
                <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Start Date - End Date</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Start - End Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.dateRange}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Location</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.meetLocation}&nbsp;</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Organization</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.organization}</div>
              </div>
            </div>
          </div>
        ))
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )}
  </div >
);

const SearchMeetGrid = ({ searchMeetState, meetState, onEdit, onFiltersToggle, onCancelClicked }) => (
  <Fragment>
    {meetState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {meetState.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{meetState.arrayData.length === 1 ? `${meetState.arrayData.length} Search Result` : `${meetState.arrayData.length} Search Results`}</p>}
        </div>
        {onFiltersToggle ?
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onFiltersToggle()}>{searchMeetState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
          </div> : <Fragment />}
      </div>
      :
      <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >
    }
    <LargeGrid state={meetState} onEdit={onEdit} onCancelClicked={onCancelClicked} />
    <SmallGrid state={meetState} onEdit={onEdit} onCancelClicked={onCancelClicked} />
  </Fragment>
);

export default SearchMeetGrid;