import { Fragment } from 'react';

import useMeetAdminStaff from './UseMeetAdminStaff';

import MeetAdminStaffGrid from './MeetAdminStaffGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

const MeetAdminStaff = () => {
  const {
    meetAdminStaffState,
    isLoading,
    isSaving,
    Constants,
    onAddAdminStaff,
    onDeleteAdminStaff,
    modalState,
    onDeleteAdminStaffClicked,
    onModalCanceled
  } = useMeetAdminStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Administrators</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <ActionIntraPageButton onClick={onAddAdminStaff}>Add Meet Administrator</ActionIntraPageButton>
        </div>
        <div className='col-xs-12'>
          <MeetAdminStaffGrid
            gridData={meetAdminStaffState.arrayData}
            isLoading={meetAdminStaffState.isArrayLoading}
            onDeleteAdminStaff={onDeleteAdminStaffClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><strong>Meet Administrator Name:</strong> {modalState.staff?.firstName || ''} {modalState.staff?.lastName || ''}</p>
            <p><strong>Email:</strong> {modalState.staff?.email || ''}</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteAdminStaff(e, modalState.staff)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default MeetAdminStaff;