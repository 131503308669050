
import { Fragment } from 'react';
import useTimesValidation from './UseTimesValidation';
import Headings from '../../../../common/components/headings/Headings';
import TimesValidationGrid from './TimesValidationGrid';  
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';
import MeetUploadStatus from '../../../../common/components/infoPanels/meetUploadStatus/MeetUploadStatus';

const TimesValidation = () => {
  const { 
    meetState, 
    timeValidationState, 
    modalState,
    meetResultsStatusState,
    onEditTime, 
    onProcessValidations,
    onDeleteTime,
    onModalCanceled,
    onOpenModalClicked,
    onRefreshStatus
  } = useTimesValidation();

  if (meetResultsStatusState.objData.isProcessing === true) {
    return (
      <MeetUploadStatus statusObject={meetResultsStatusState.objData} onRefreshStatus={onRefreshStatus} />
    )
  }
  else {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Times Validation for - {meetState.objData.meetName}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <SecondaryButton onClick={onProcessValidations}>Re-process Validations</SecondaryButton>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <TimesValidationGrid
            gridData={timeValidationState.arrayData}  
            isLoading={false}
            onEditTime={onEditTime}
            onDeleteTime={onOpenModalClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Name:</b> {modalState.swimmerName}</p>
            <p><b>Time: </b> {modalState.swimTime}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteTime}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeValidationState.isArrayLoading || meetResultsStatusState.isObjLoading} />
    </Fragment>);
  }
}

export default TimesValidation; 