import { useContext } from 'react';

import LaneSheetHeatData from './LaneSheetHeatData';

import { LaneSheetHeatStateContext } from './LaneSheetHeatContextProvider';

const useLaneSheetHeatData = () => {
  const [laneSheetHeatState, setLaneSheetHeatState] = useContext(LaneSheetHeatStateContext);

  const postBulkLaneSheetHeatOme = (laneSheetMeetId) => {
    return LaneSheetHeatData.postBulkLaneSheetHeatOmeData(laneSheetMeetId, laneSheetHeatState, setLaneSheetHeatState);
  };

  const postPopulateLaneSheetHeatFinals = (laneSheetMeetId) => {
    return LaneSheetHeatData.postPopulateLaneSheetHeatFinalsData(laneSheetMeetId, laneSheetHeatState, setLaneSheetHeatState);
  };

  const postLaneSheetHeat = (laneSheetMeetId, laneSheetHeatObj, state, setState) => {
    return LaneSheetHeatData.postLaneSheetHeatData(laneSheetMeetId, laneSheetHeatObj, state, setState);
  };

  const getLaneSheetHeats = (laneSheetMeetId) => {
    return LaneSheetHeatData.getLaneSheetHeatsData(laneSheetMeetId, laneSheetHeatState, setLaneSheetHeatState);
  };

  const putLaneSheetHeatDisplayOrder = (laneSheetMeetId, laneSheetHeatsArray) => {
    return LaneSheetHeatData.putLaneSheetHeatDisplayOrderData(laneSheetMeetId, laneSheetHeatsArray, laneSheetHeatState, setLaneSheetHeatState);
  };

  const deleteLaneSheetHeat = (laneSheetHeatId, state, setState) => {
    return LaneSheetHeatData.deleteLaneSheetHeatData(laneSheetHeatId, state, setState);
  };

  return {
    laneSheetHeatState,
    postBulkLaneSheetHeatOme,
    postPopulateLaneSheetHeatFinals,
    postLaneSheetHeat,
    getLaneSheetHeats,
    putLaneSheetHeatDisplayOrder,
    deleteLaneSheetHeat
  };
};

export default useLaneSheetHeatData;