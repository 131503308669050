import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const postBulkLaneSheetHeatOmeData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/LaneSheetHeat/Ome`;

    return api?.executeArray ? api.executeArray(url, 'POST') : null;
  }
};

const postPopulateLaneSheetHeatFinalsData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/LaneSheetHeat/Finals`;

    return api?.executeArray ? api.executeArray(url, 'POST') : null;
  }
};

const postLaneSheetHeatData = (laneSheetMeetId, laneSheetHeatObj, state, setState) => {
  if (laneSheetMeetId > 0 && laneSheetHeatObj) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/LaneSheetHeat`;

    return api?.executeObject ? api.executeObject(url, 'POST', laneSheetHeatObj) : null;
  }
};

const getLaneSheetHeatsData = (laneSheetMeetId, state, setState) => {
  if (laneSheetMeetId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/LaneSheet`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putLaneSheetHeatDisplayOrderData = (laneSheetMeetId, laneSheetHeatObj, state, setState) => {
  if (laneSheetMeetId > 0 && laneSheetHeatObj) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetMeetIdForUrl = encodeURIComponent(laneSheetMeetId);
    const url = `/LaneSheetMeet/${laneSheetMeetIdForUrl}/LaneSheetHeat`;

    return api?.executeArray ? api.executeArray(url, 'PUT', laneSheetHeatObj) : null;
  }
};

const deleteLaneSheetHeatData = (laneSheetHeatId, state, setState) => {
  if (laneSheetHeatId > 0) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const laneSheetHeatIdForUrl = encodeURIComponent(laneSheetHeatId);
    const url = `/LaneSheetHeat/${laneSheetHeatIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const LaneSheetHeatData = {
  postBulkLaneSheetHeatOmeData,
  postPopulateLaneSheetHeatFinalsData,
  postLaneSheetHeatData,
  getLaneSheetHeatsData,
  putLaneSheetHeatDisplayOrderData,
  deleteLaneSheetHeatData
};

export default LaneSheetHeatData;